//site
$primary-h: 212 !default;
$primary-s: 63 !default;
$primary-b: 48 !default;

$primary: #2d74c7;

// $secondary: #576012;
//$secondary;
//$tertiary: #fed505;
//$tertiary-text: $white;

//$header-bg-color: $monet-blue;
//$header-center-bg-color: $header-bg-color;

//$headings-color: $primary;
//$semibold: 600;
