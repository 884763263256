// temporaneo, questa modifica sarà messa a regime nei prossimi dvt >= v11.27, > 12.0.0-alpha.36

body.is-authenticated {
  // se l'utente è autenticato e c'è la classe no-user-roles, allora si tratta
  // di un utente del sito che accede solo all'area riservata (cittadino)
  &.no-user-roles, &.role-citizen {
    #toolbar,
    #sidebar {
      display: none;
    }

    &.has-toolbar,
    &.has-toolbar-collapsed {
      .full-width {
        left: 50%;
        width: 100vw !important;
      }
    }
  }
}

